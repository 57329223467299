<template>
    <div class="w-100 pb-5 pt-lg--10">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row justify-content-center my-5 content">
                    <div class="col-12">
                        <div class="col-12">
                            <div class="card e_card mb-5">
                                <div class="card-body p-0">
                                    <h1 class="text-grey-900 fw-700 font-lg mb-2 d-block">Welcome to Thread Assignment
                                        Services</h1>
                                    <p class="p-0 mb-4 text-grey-900">Get corrected assignments and formative feedback for
                                        various task types.</p>
                                    <router-link to="/register" class="btn btn-success e_btn">Register</router-link>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-md-6 col-sm-12 px-2" v-for="(item, index) in cardData"
                                    :key="index">
                                    <div class="card e_card_feature mb-3">
                                        <div class="card-body p-0">
                                            <img :src="item.Icon" />
                                            <h2 class="card-title fw-600 mt-4 mb-3">{{ item.Title }}</h2>
                                            <div class="pt-lg-3">
                                                <p class="card-text" v-if="item.showFullDescription">{{ item.Description }}
                                                </p>
                                                <p class="card-text" v-else>
                                                    {{ item.Description.substring(0, 70) }}...
                                                    <span v-if="item.Description.length > 70" class="read-more"
                                                        @click="toggleDescription(item)">
                                                        Read More
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style>
.e_card {
    border-radius: 24px;
    background: #FBAF03;
    padding: 40px 32px;
}

.e_card_feature {
    border-radius: 20px;
    background: #F6F7FC;
    padding: 24px;
}

.e_btn {
    color: #fff;
    background-color: #1E6F5C;
    border-color: #1E6F5C;
    border-radius: 12px;
    padding: 8px 16px;
}

.card-text {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-self: stretch;
    font-size: 16px;
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;
    whitespace: nowrap;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.18px;
}

.content {
    margin-top: 48px !important;
}

.read-more {
    cursor: pointer;
    color: blue;
    font-weight: 500;
    margin-top: 4px;
}

/* Media query for tablet and mobile screens */
@media (max-width: 991.98px) {
    .content {
        margin-top: 96px !important;
    }
}
</style>

<script>
export default {
    data() {
        return {
            cardData: [
                {
                    Icon: "/images/ic/ic_th_dis.svg",
                    Title: "Discussion",
                    Description:
                        "Improve your writing and reading skills through discussions. Get corrections and feedback to enhance your language proficiency.",
                    showFullDescription: false
                },
                {
                    Icon: "/images/ic/ic_th_qz.svg",
                    Title: "Quiz",
                    Description:
                        "Enhance your reading and listening skills with quizzes. Receive corrections and feedback to strengthen your language comprehension.",
                    showFullDescription: false
                },
                {
                    Icon: "/images/ic/ic_th_ia.svg",
                    Title: "Individual Assignment",
                    Description:
                        "Receive feedback on your individual assignments with a focus on listening and speaking skills.",
                    showFullDescription: false
                },
                {
                    Icon: "/images/ic/ic_th_ga.svg",
                    Title: "Group Assignment",
                    Description:
                        "Work on writing and speaking tasks in groups. Receive comprehensive feedback to improve collaborative language skills.",
                    showFullDescription: false
                },
                {
                    Icon: "/images/ic/ic_th_at.svg",
                    Title: "Achievement Test",
                    Description:
                        "Prepare for comprehensive language tests covering listening, reading, writing, and speaking skills. Get corrections and feedback to excel.",
                    showFullDescription: false
                },
            ],
        };
    },
    methods: {
        toggleDescription(item) {
            item.showFullDescription = !item.showFullDescription;
        }
    }
};
</script>
